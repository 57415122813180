<template>
  <div id="topicpersonal">
    <div class="title">
      <b>*资源库导入试题</b>
      <div class="topictype">
        <div class="left">
          <el-select v-model="type" placeholder="请选择专业">
            <el-option
              label="电机与电气控制技术"
              value="电机与电气控制技术"
            ></el-option>
            <el-option
              label="电机与电极与点击与惦记与典籍"
              value="电机与电极与点击与惦记与典籍"
            ></el-option>
          </el-select>
        </div>
        <div class="right">
          <el-select v-model="timer" placeholder="请选择时间">
            <el-option label="2021" value="2021"></el-option>
            <el-option label="2020" value="2020"></el-option>
            <el-option label="2019" value="2019"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <ul>
      <li @click="toLocal">
        <div class="img">
          <img src="../../img/板块图标 - 副本_slices/04_无数据.png" alt="" />
        </div>
        <div class="info">
          <div class="txt">高压变电站的原理课程试题</div>
          <div class="box">
            <div class="see">
              <img
                src="../../img/板块图标 - 副本_slices/观看.png"
                alt=""
                style="width: 0.14rem"
              />
              <span>预览</span>
            </div>
            <div class="infos">
              <img
                src="../../img/板块图标 - 副本_slices/查看.png"
                alt=""
                style="width: 0.12rem"
              />
              <span>查看详情</span>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="img">
          <img src="../../img/板块图标 - 副本_slices/04_无数据.png" alt="" />
        </div>
        <div class="info">
          <div class="txt">高压变电站的原理课程试题</div>
          <div class="box">
            <div class="see">
              <img
                src="../../img/板块图标 - 副本_slices/观看.png"
                alt=""
                style="width: 0.14rem"
              />
              <span>预览</span>
            </div>
            <div class="infos">
              <img
                src="../../img/板块图标 - 副本_slices/查看.png"
                alt=""
                style="width: 0.12rem"
              />
              <span>查看详情</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import * as util from "@/unit/network";
export default {
  data() {
    return {
      type: "",
      timer: "",
    };
  },
  mounted() {
    util.post(
      "/teachResPool",
      { file_type: this.$route.query.filetype },
      (res) => {
        console.log(res);
      }
    );
  },
  methods: {
    toLocal() {
      this.$router.push({
        path: "/main/addscene/topicresource/topiclocal/topiclocal1",
      });
    },
  },
};
</script>
<style lang="less" scoped>
#topicpersonal {
  height: 100%;
  background-color: #fff;
  padding: 30px 78px 0 75px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topictype {
      display: flex;
      align-items: center;
      .left {
        margin-right: 101px;
      }
    }
  }
  ul {
    margin: 15px 26px 0;
    li {
      width: 238/96in;
      height: 239/96in;
      float: left;
      background: #fafafa;
      box-shadow: 0px 0px 5px 1px rgba(145, 164, 210, 0.38);
      margin-right: 75px;
      margin-top: 30px;
      .img {
        width: 238/96in;
        height: 140/96in;
        overflow: hidden;
        img {
          width: 100%;
        }
      }

      .info {
        padding: 14px;
        font-size: 12/96in;
        .txt {
          font-weight: 400;
          color: #000000;
          margin-top: 12px;
          margin-bottom: 24px;
        }
        .box {
          display: flex;
          justify-content: flex-end;
          .see {
            margin-right: 17px;
          }
          span {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>